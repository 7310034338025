import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './App.css';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import SlideUpPanel from "./SlideUpPanel";
import firebase from './firebase';
import Loader from "./Loader";
import $ from 'jquery';
import AppComponent from "./AppComponent";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Cookies from "universal-cookie/es6";
import BirthdayConsent from "./BirthdayConsent";
import Validator from "./Validator";
import Ranking from "./Ranking";
import RankingComponent from "./RankingComponent";

const jss = create({
    ...jssPreset(),
    // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
    insertionPoint: document.getElementById('jss-insertion-point'),
});


export default class App extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lastName: '',
            email: '',
            uid: '',
            login: false,
            fid: '',
            birthConsent: false,
            friendUID: window.location.href.substr(window.location.href.toString().lastIndexOf('/') + 1),
        };
    }

    componentDidMount() {

        const cookies = new Cookies();
        var db = firebase.firestore();
        var campRef = db.collection('campaigns').doc('bohemiaviral');
        var hasUsed = cookies.get('hasUsed2');
        var birthConsent = cookies.get('birthConsent');

        if (birthConsent){

            this.setState({
                birthConsent: true,
            })
        }

        /*db.runTransaction(function (transaction) {
            return transaction.get(campRef).then(function (camp) {
                if (!camp.exists) {
                    throw "Document does not exist!";
                }
                var newTraffic = camp.data().traffic + 1;

                transaction.update(campRef, {traffic: newTraffic});
                return newTraffic;

            });
        }).then(function (newPopulation) {

        }).catch(function (err) {
            // This will be an "population is too big" error.
            console.error(err);
        });

        if (!hasUsed) {
            let d = new Date();
            d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
            cookies.set('hasUsed2', true, {expires: d});
            db.runTransaction(function (transaction) {
                return transaction.get(campRef).then(function (camp) {
                    if (!camp.exists) {
                        throw "Document does not exist!";
                    }
                    var newTrafficUsers = camp.data().trafficUsers + 1;

                    var newConsumerShare = camp.data().consumerShare;
                    var newBrandShare = camp.data().brandShare;

                    if (context.state.friendUID) {
                        newConsumerShare = newConsumerShare + 1
                    } else {
                        newBrandShare = newBrandShare + 1;
                    }

                    /!*var facebookVis = camp.data().facebookVis;
                    var instagramVis = camp.data().instagramVis;
                    var storiesVis = camp.data().storiesVis;
                    var mailingVis = camp.data().mailingVis;



                    var friendUID = context.state.friendUID;
                    if (friendUID) {
                        if (friendUID === 'facebook'){
                            facebookVis = facebookVis + 1;
                            newBrandShare = newBrandShare + 1;
                        }
                        else if(friendUID === 'instagram'){
                            instagramVis = instagramVis + 1;
                            newBrandShare = newBrandShare + 1;
                        }
                        else if(friendUID === 'stories'){
                            storiesVis = storiesVis + 1;
                            newBrandShare = newBrandShare + 1;
                        }
                        else if(friendUID === 'mailing'){
                            mailingVis = mailingVis + 1;
                            newBrandShare = newBrandShare + 1;
                        }
                        else{
                            newConsumerShare = newConsumerShare + 1
                        }
                    } else {
                        newBrandShare = newBrandShare + 1;
                    }*!/



                    transaction.update(campRef, {
                        trafficUsers: newTrafficUsers,
                        consumerShare: newConsumerShare,
                        brandShare: newBrandShare,
                       /!* facebookVis: facebookVis,
                        instagramVis: instagramVis,
                        storiesVis: storiesVis,
                        mailingVis: mailingVis*!/
                    });
                    return newTrafficUsers;

                });
            }).then(function (newPopulation) {

                if (context.state.friendUID){
                    if (context.state.friendUID !== 'facebook' && context.state.friendUID !== 'instagram' && context.state.friendUID !== 'stories' && context.state.friendUID !== 'mailing') {
                        var friendRef = db.collection('users').doc(context.state.friendUID);
                        db.runTransaction(function (transaction) {
                            return transaction.get(friendRef).then(function (doc) {
                                if (!doc.exists) {
                                    throw "Document does not exist!";
                                }

                                var newShare = doc.data().shareTo + 1;
                                transaction.update(friendRef, {shareTo: newShare});
                            });
                        })

                    }
                }



            }).catch(function (err) {
                // This will be an "population is too big" error.
                console.error(err);
            });
        }
*/
        var context = this;
        firebase.auth().getRedirectResult().then(function(result) {
            $("#loading").fadeOut();
            $("#loading .object").delay(700).fadeOut("slow");
            $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);

            if (result.credential) {
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                var token = result.credential.accessToken;
                // ...
            }
            // The signed-in user info.

            var user = result.user;
            var splitedString = user.displayName.split(' ');


            var firstName = splitedString[0];
            var lastName = '';

            for (var i=1; i<splitedString.length; i++){
                if (i === 1){
                    lastName = splitedString[i];
                }
                else {
                    lastName = lastName + ' ' + splitedString[i];
                }
            }
            context.setState({
                name: firstName,
                lastName: lastName,
                email: user.email,
                uid: user.uid,
                login: true,
                fid: user.providerData[0].uid
            });


        }).catch(function(error) {

            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;

            /*setTimeout(()=> {context.setState({login: 2})},700);*/

            // ...
        });
    }

    handleChangeLogin = () =>{
        this.setState({
            login: !this.state.login,
        })
    };

    handleBirthConsent = (birthday) => {
        const cookies = new Cookies();
        var birthConsent = cookies.get('birthConsent');
        let d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        cookies.set('birthConsent', true, {expires: d});
        cookies.set('birthday', birthday, {expires: d});
        this.setState({
            birthConsent: true,
        })
    };


    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#3591FF',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });
        return (
            <Router>
                <StylesProvider jss={jss}>
                    <MuiThemeProvider theme={theme}>
                        <div className="App">
                            <div id="loading">
                                <div id="loading-center">
                                    <div id="loading-center-absolute">
                                        <div className="object" id="object_four"></div>
                                        <div className="object" id="object_three"></div>
                                        <div className="object" id="object_two"></div>
                                        <div className="object" id="object_one"></div>
                                    </div>
                                </div>
                            </div>
                            <Switch>
                                <Route
                                    path='/validator/:id'
                                    render={(props) =>
                                        <div className={''}>
                                            <Validator/>
                                        </div>}
                                />
                              {/*  <Route
                                    path='/ranking'
                                    render={(props) =>
                                        <div className={''}>
                                            <RankingComponent/>
                                        </div>}
                                />*/}
                                <Route
                                    path='/privacypolicy'
                                    render={(props) =>
                                        <div className={'privacyPolicy'}>
                                            <p>
                                            </p>
                                        </div>}
                                />
                                <Route
                                    path='/'
                                    render={(props) =>

                                        <div className={''}>
                                            {this.state.birthConsent &&
                                            <AppComponent handleChangeLogin={this.handleChangeLogin} fid={this.state.fid} name={this.state.name} lastName={this.state.lastName} email={this.state.email} uid={this.state.uid} login={this.state.login}/>
                                            }
                                            {!this.state.birthConsent && <BirthdayConsent handleBirthConsent={this.handleBirthConsent}/>}
                                        </div>}
                                />
                            </Switch>



                        </div>
                    </MuiThemeProvider>
                </StylesProvider>
            </Router>
        )
    }
}